<!--
 * @version: 20220211
 * @Author: zgy
 * @Date: 2022-08-03 15:57:34
 * @LastEditors: zgy
 * @LastEditTime: 2022-08-03 17:36:29
 * @Description: description
-->
<template>
  <div class="container member-box">  
    <div class='skeleton-hide topUserinfo1'>
      <img class='b_bg' mode="aspectFill" src='https://cdn.teamax-cn.cn/static/Images/pay/b_bg.png' />
      <div class="skeleton-hide navbar1">
        <div class="nav">
          <div class="left-arrow" @click="back"></div>
          <div class="nav-title">商户设置</div>
        </div>
      </div>
      <div class="business-info">
        <div class="userinfo-avatar">
          <img src="../assets/images/shop-logo.png" class="avatar" alt="">
        </div>
        <span class="store-name">{{fullName}}</span>
      </div>
    </div>
    <div class="item-list">
      <router-link to="/applylist" class="label rowbetween">
        <div class="rowCenter item-title">
          <img src="../assets/images/safeicon.png" mode="widthFix" class="item-icon store-icon" />
          <span>开通产品</span>
        </div>
        <div class="more"></div>
      </router-link>
      <router-link to="/store" class="label rowbetween">
        <div class="rowCenter item-title">
          <img src="../assets/images/storeicon.png" mode="widthFix" class="item-icon store-icon" />
          <span>门店管理</span>
        </div>
        <div class="more"></div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { getBaseInfoApi } from '@/api/login'
export default {
  name:'business',
  setup() {
    const router = useRouter()

    const state = reactive({
      fullName: ''
    })

    onMounted(async() => {
      const { data } = await getBaseInfoApi()
      state.fullName = data.fullName
    })

    const back = () => {
      router.go(-1)
    }


    return {
      ...toRefs(state),
      back
    }
  }
};
</script>

<style lang="less" scoped>
/* 导航 */
.member-box {
  width: 100%;
  height: 100vh;
  background: #efefef;
  .rowCenter {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.member-box .topUserinfo1 {
  height: auto;
  width: 100%;
  position: relative;
  .b_bg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .nav{
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 0 0;
  }
  .left-arrow{
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    position: absolute;
    left: 15px;
  }
  .navbar1 {
    color: #000;
    position: relative;
    z-index: 999;
    width: 100%;
    .nav-title {
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
  }
  .business-info{
    padding: 0 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    .userinfo-avatar{
      width: 49px;
      height: 49px;
      border-radius: 49px;
      overflow: hidden;
      margin-top: 25px;  
      border: 1px solid #FFFFFF;
      background-color: #FFFFFF;
      .avatar {
        width: 100%;
        height: 100%;
      }
    }
    .store-name{
      margin-top: 15px;
      font-size: 15px;
      color: #FFFFFF;
    }
  }
} 
.item-list .label{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  padding: 0 15px;
  margin-top: 10px;
  background-color: #FFFFFF;
  justify-content: space-between;
  .item-title{
    font-size: 15px;
    color: #333333;
  }
  .item-icon{
    width: 16px;
    margin-right: 15px;
  }
  .more{
    flex: none;
    width: 7px;
    height: 7px;
    border-top: 1px solid #888;
    border-right: 1px solid #888;
    transform: rotate(45deg);
  }
}
</style>